import {create} from "zustand";
import {MerchantType} from "../../types/MerchantType";
import {MerchantAuthService} from "../../api/MerchantAuthService";

interface IMerchantAuthStore {
    merchant: null | MerchantType;
    setMerchant: (merchant: MerchantType) => void;
    isMerchantAuthProcessing: boolean
    isLoggingOut: boolean;
    isAuthChecking: boolean
    setAuthChecking: (isAuthChecking: boolean) => void

    setMerchantAuthProcessing: (status: boolean) => void
    login: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
    registration: (email: string, password: string, registrationKey: string) => Promise<void>
    refreshTokens: () => Promise<void>,
    changePassword: (currentPassword: string, newPassword: string) => Promise<void>,
    isPasswordChanging: boolean,
    setPasswordChanging: (isPasswordChanging: boolean) => void,
}

export const useMerchantAuthStore = create<IMerchantAuthStore>((set, get) => ({
    merchant: null,
    isLoggingOut: false,
    setMerchant: (merchant: MerchantType) => set({merchant}),
    isAuthChecking: true,
    setAuthChecking: (isAuthChecking: boolean) => set({isAuthChecking}),
    isMerchantAuthProcessing: false,
    isPasswordChanging: false,
    setPasswordChanging: (isPasswordChanging: boolean) => set({isPasswordChanging}),
    setMerchantAuthProcessing: (isMerchantAuthProcessing: boolean) => set({isMerchantAuthProcessing}),
    login: async (email: string, password: string) => {
        set({isMerchantAuthProcessing: true});
        const res = await MerchantAuthService.login({email, password})
        localStorage.setItem('accessToken', res.data.session.accessToken)

        set({isMerchantAuthProcessing: false, merchant: res.data.merchant});
    },
    registration: async (email: string, password: string, registrationKey: string) => {
        set({isMerchantAuthProcessing: true});
        const res = await MerchantAuthService.registration({email, password, registrationKey, name: '', organizationDesc: ''})
        console.log({res})
        localStorage.setItem('accessToken', res.data.session.accessToken)

        set({isMerchantAuthProcessing: false, merchant: res.data.merchant});
    },
    refreshTokens: async () => {
        set({isAuthChecking: true});
        const res = await MerchantAuthService.refresh()
        localStorage.setItem('accessToken', res.data.session.accessToken);
        const merchantData = await MerchantAuthService.getMerchantData().then(res => res.data)

        set(state => ({...state, isAuthChecking: false, merchant: merchantData}))
    },
    changePassword: async (password: string, newPassword: string) => {
        set({isPasswordChanging: true});
        const res = await MerchantAuthService.changePassword(password, newPassword)

        set({isPasswordChanging: false});
    },
    logout: async () => {
        set({isLoggingOut: true})
        await MerchantAuthService.logout()
        set({merchant: null, isLoggingOut: false})
    }
})
)