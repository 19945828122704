import {FC, useEffect, useState} from 'react'
import AuthInput from "../../../../UI/AuthInput/AuthInput";
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import Button from "../../../../UI/Button/Button";
import styles from "./LoginForm.module.scss";
import { useForm, SubmitHandler } from "react-hook-form"
import {useMerchantAuthStore} from "../../store/merchantAuthStore/merchantAuthStore";
import {AxiosError} from "axios";
import {Link} from "react-router-dom";
import GradientButton from "../../../../UI/GradientButton/GradientButton";

interface Inputs {
    email: string
    password: string
    isEmployee: boolean
}

const LoginForm: FC = () => {
    const [isShown, setShown] = useState(false)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        watch
    } = useForm<Inputs>()

    const { login, setMerchantAuthProcessing, isMerchantAuthProcessing } = useMerchantAuthStore((state) => ({
        merchant: state.merchant,
        login: state.login,
        isMerchantAuthProcessing: state.isMerchantAuthProcessing,
        setMerchantAuthProcessing: state.setMerchantAuthProcessing
    }));

    const email = watch('email')
    const password = watch('password')

    const isButtonDisabled = isMerchantAuthProcessing || !email || !password

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (isButtonDisabled) return
        try {
            await login(data.email, data.password)
        } catch(e) {
            setMerchantAuthProcessing(false)
            if (e instanceof AxiosError) {
                const errData = e?.response?.data
                if (errData.message === 'Validation failed' && Array.isArray(errData.errors)) {
                    for (let error of errData.errors) {
                        setError(error.field, {message: error.message})
                    }
                }
                if (errData.message && errData.field) setError(errData.field, {message: errData.message})
            }
            console.log(e)
        }
    }

    return (
        <form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
            <h1>Войти в аккаунт</h1>
            <h3>Введите данные для входа</h3>

            <div className={styles.prompt}>
                <span>У вас нет аккаунта ?</span>
                <Link to={'/registration'}>Зарегистрируйтесь</Link>
            </div>
                <AuthInput className={styles.auth_input}
                           type="email" title="Email"
                           {...register('email')}
                           error={errors.email?.message}
                />
                <AuthInput isWithIcon={true}
                           onIconClick={() => setShown(!isShown)}
                           isShown={isShown}
                           className={styles.auth_input}
                           type={isShown ? 'text' : 'password'}
                           title="Пароль"
                           error={errors.password?.message}
                           {...register('password')}
                />

            {/*<div className={styles.employee_status__forgot_password}>*/}
            {/*    <Checkbox {...register('isEmployee')} />*/}
            {/*    <a href='#'>Забыли пароль ?</a>*/}
            {/*</div>*/}

            <GradientButton disabled={isButtonDisabled} loading={isMerchantAuthProcessing} className={styles.btn}>Войти</GradientButton>
        </form>
    )

}

export default LoginForm