import {create} from "zustand";
import {PAYMENT_STATUS} from "../../../merchant/config/MerchantPaymentStatus";

interface ICustomerPaymentSSEStore {
    paymentSSEStatus: PAYMENT_STATUS
    setPaymentSSEStatus: (paymentSSEStatus: PAYMENT_STATUS) => void
}

export const useCustomerPaymentSSEStore = create<ICustomerPaymentSSEStore>((set, get) => ({
    paymentSSEStatus: PAYMENT_STATUS.CREATED,
    setPaymentSSEStatus: (paymentSSEStatus: PAYMENT_STATUS) => set({paymentSSEStatus: paymentSSEStatus})}
))