import styles from './MerchantTable.module.scss';
import classNames from "classnames";
import created from '../../../../assets/status/created.svg'
import fulfilled from '../../../../assets/status/fulfilled.svg'
import expired from '../../../../assets/status/expired.svg'
import {useMerchantPaymentMethodsStore} from "../../store/merchantPaymentsStore/merchantPaymentMethodsStore";
import {useEffect} from "react";
import {generatePaymentLink} from "../../utils/generatePaymentLink";
import CopyBlock from "../CopyBlock/CopyBlock";
import {MerchantPayment} from "../../types/MerchantPayment";
import {PAYMENT_STATUS} from "../../config/MerchantPaymentStatus";

const MerchantTable = () => {

    const {payments, isPaymentsFetching, setPaymentsFetching, getPayments} = useMerchantPaymentMethodsStore(store => ({
        payments: store.payments,
        isPaymentsFetching: store.isPaymentsFetching,
        setPaymentsFetching: store.setPaymentsFetching,
        getPayments: store.getPayments
    }))

    useEffect(() => {
        getPayments()
    }, []);

    return (
        <div className={styles.table_container}>
            <table>
                <thead>
                <tr>
                    <th>ID платежа</th>
                    <th>Товар / Услуга</th>
                    <th>Сумма</th>
                    <th>Статус</th>
                    <th>Ссылка</th>
                </tr>
                </thead>
                <tbody>
                {payments.map((payment, index) => (
                    <tr key={index}>
                        <td>
                            <CopyBlock
                                defaultText={payment.id}
                                copiedText="Скопировано"
                                textToCopy={payment.id}
                                className={styles.copy_block}
                            />
                        </td>
                        <td>
                            <CopyBlock
                                defaultText={payment.productOrService}
                                copiedText="Скопировано"
                                textToCopy={payment.productOrService || ''}
                                className={styles.copy_block}
                            />
                        </td>
                        <td>{payment.paymentAmountUSD} $</td>
                        <td>
                            <span className={classNames(styles.status_icon, styles.success)}>
                                {
                                    <img
                                        src={payment.status === PAYMENT_STATUS.MERCHANT_PAID ? fulfilled : new Date(payment.expiresAt) < new Date() ? expired : created}
                                        alt="status icon"
                                        className={styles.status_icon}
                                    />
                                }
                            </span>
                        </td>
                        <td>
                            <CopyBlock
                                defaultText="Копировать"
                                copiedText="Скопировано"
                                textToCopy={generatePaymentLink(payment.paymentJWTToken)}
                                className={styles.copy_block}
                            />
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>

            {!payments.length && <h3 style={{textAlign: 'center', marginTop: '2rem'}}>Здесь пока нет данных</h3>}
        </div>
    )
}

export default MerchantTable;