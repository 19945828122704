import axios from "axios";
import {CONFIG} from "../../../config/Config";
import {AuthResponse} from "../api/responses/AuthResponse";

const $axios = axios.create({
    withCredentials: true,
    baseURL: CONFIG.apiBaseUrl,
})

console.log('API BASE URL: ', CONFIG.apiBaseUrl)

$axios.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`

    return config;
})

$axios.interceptors.response.use((config) => {
    return config;
},async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await axios.get<AuthResponse>(`${CONFIG.apiBaseUrl}/merchant/refresh`, {withCredentials: true})
            localStorage.setItem('accessToken', response.data.session.accessToken);
            return $axios.request(originalRequest);
        } catch (e) {
            console.error('UNAUTHORIZED')
        }
    }
    throw error;
})



export default $axios

